import "../styles/homepage.css"
function Homepage(){
 
    return(
        
    <div className="homeContainer">
        <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/devicons/devicon@v2.15.1/devicon.min.css" />
        <img className="homeImg" src={require('../assets/BBb.png')} alt=""/>
        <div className="contentContainer">
            <div className="content">
                <h1>About Me</h1>
                <p>I'm a full stack web developer that graduated from the software engineering course at Flatiron School. I've become skilled in JavaScript, React, Ruby, Ruby on Rails, and more! I'm always finding more ways to expand my skill set and knowledge.</p>
                <div className="languageLogos">
                    <i class="devicon-react-original-wordmark" />
                    <i class="devicon-javascript-plain"/>
                    <i class="devicon-css3-plain-wordmark"/>
                    <i class="devicon-html5-plain-wordmark"/>
                    <i class="devicon-postgresql-plain-wordmark"/>
                    <i class="devicon-visualstudio-plain"/>
                    <i class="devicon-git-plain"/>
                    <i class="devicon-github-original-wordmark"/>
                    <i class="devicon-ruby-plain"/>
                    <i class="devicon-rails-plain-wordmark"/>
                </div>
               
            </div>
            <div className="contentContainer">
                 <div className="content">
                    <h1>Contact Me!</h1>
                    <h2>Email me at braylan1005@gmail.com</h2>
                </div>
            </div>
            <div className="contentContainer">
                <div className="content">
                    <h1>Grab my Resume!</h1>
                    <h2><a href={require("../assets/downloadables/test.txt")} download={true}>Resume</a></h2>
                </div>
            </div>
        </div>
    </div>
 )
}
export default Homepage