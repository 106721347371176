import './App.css';
import Homepage from './components/Homepage';
import PortfolioPage from './components/PortfolioPage';
import NavBar from './components/NavBar';
import BlogPage from './components/BlogPage';
import {  Route, Routes } from 'react-router-dom'

function App() {
  return (
    <div className="App">
    
     <Routes>
      <Route path="/" element={[<NavBar key="NavBar"/>, <Homepage key="HomePage"/>]}/>
      <Route path="/portfolio" element={[<NavBar key="NavBar"/>, <PortfolioPage key="PortfolioPage"/>]}/>
      <Route path="/blogs" element={[<NavBar key="NavBar"/>, <BlogPage key="BlogPage"/>]}/>
     </Routes>
    </div>
  );
}

export default App;
