import "../styles/navBar.css"

import { NavLink} from 'react-router-dom'

function NavBar (){
    return(
        <div className="navBarContainer">
            <NavLink className="navLogo" to="/"><img src={require('../assets/BBbT.png')} alt="Braisin Bran Logo" /></NavLink>
            <NavLink to="/portfolio">Portfolio</NavLink>
            <NavLink to="/blogs">Blogs</NavLink>
        </div>
    )
}
export default NavBar